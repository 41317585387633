import { create } from "zustand";
import { LOCAL_STORAGE_STORE_DISPLAY_KEY } from "../constants";

const useStoreDisplay = create(( set, get ) => ({
    isBooted: false,
    requiredItems: [],
    scannedItems: [],
    boot: (storeDisplay) =>  set(() => {
        const {requiredItems, scannedItems} = storeDisplay
        return {
            isBooted: true,
            requiredItems,
            scannedItems,
        }
    }),
    setStoreDisplay: async (storeDisplay) => set(() => {
        const currentState = get();
        const {requiredItems, scannedItems} = storeDisplay
        return {
            requiredItems: requiredItems || currentState.requiredItems,
            scannedItems: scannedItems || currentState.scannedItems
        };
    }),
    addScannedItem: async (scannedItem) => set((state) => {
        const {id} = scannedItem
        if (state.scannedItems.includes(id)) return state
        return {
            scannedItems: [...state.scannedItems, id]
        }
    }),
    getMissingItems: () => {
        const {requiredItems, scannedItems} = get()
        const missingItems = requiredItems.filter((requiredItem) => !scannedItems.includes(requiredItem.id))
        return missingItems
    },
    getRequiredItemByBarcode: (barcode) => {
        const {requiredItems} = get()
        return requiredItems.find((requiredItem) => (
            requiredItem.fullExternalIds.some((externalId) => externalId.startsWith(barcode)) || 
            requiredItem.fullEans.includes(barcode)
        ))
    }
}))

useStoreDisplay.subscribe(async (state) => {
    try {
        console.log("🚀 ~ useStoreDisplay.subscribe ~ state:", state)
        localStorage.setItem(LOCAL_STORAGE_STORE_DISPLAY_KEY, JSON.stringify(state));
    } catch (e) {
        console.error('Error saving to localStorage:', e);
    }
});

export default useStoreDisplay;