import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import useShopCartStore from '../../../hooks/useShopCartStore'
import { userRoleSelector, userSelector } from '../../../ducks/access/selectors';
import { updateShopCart } from '../../../services/api';
import { getUserCommerce, updateLocalStorageShopCartItems } from '../../../utils';
import { LOCAL_STORAGE_STORE_DISPLAY_KEY, ROLE_ANONYMOUS, ROLE_COMMERCE, ROLE_PROMOTER } from '../../../constants';
import ConfirmationMessageModal from '../../confirmationMessageModal/ConfirmationMessageModal';
import useScandit from '../../../hooks/useScandit';
import createSDKFacade from '../../../sdk/scanditSdk';
import useStoreDisplay from '../../../hooks/useStoreDisplay';

const ShopCartStore = ({children}) => {
    const isScanditLoaded = useScandit((state) => state.isLoaded)
    const setScanditSdk = useScandit((state) => state.setSdk)
    const loadBootItems = useShopCartStore((state) => state.loadBootItems)
    const shopCartReset = useShopCartStore((state) => state.shopCartReset)
    const shopCart = useShopCartStore((state) => state.shopCart, (prev, next) => {
        if (!prev || !next) return false;
        const prevVersion = prev?.version
        const nextVersion = next?.version            
        return prevVersion === nextVersion
    })
    const bootStoreDisplayItems = useStoreDisplay((state) => state.boot)

    const [isFirstSyncItemsTime, setIsFirstSyncItemsTime] = useState(true)
    const user = useSelector(userSelector);
    const userRole = useSelector(userRoleSelector)
    
    const syncShopCartItemStorage = async ({user, items}) => {
        const userCommerce = getUserCommerce(user)

        if (userRole === ROLE_PROMOTER) {
            try {
                await updateShopCart({
                    items
                })
            } catch (error) {
                console.log("🚀 ~ onClickCart ~ error:", error)
            }
        }

        if (userRole === ROLE_COMMERCE) {
            try {
                updateLocalStorageShopCartItems(userCommerce.id, items)
            } catch (error) {
                console.log("🚀 ~ syncShopCartItemStorage ~ error:", error)
            }
        }
    }

    const loadScandit = async () => {
        try {
            if (!isScanditLoaded && [ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)) {
                const sdkInstance = createSDKFacade();
                await sdkInstance.initialize();
                setScanditSdk(sdkInstance)
            }
        } catch (error) {
            console.log("🚀 ~ loadScandit ~ error:", error)
        } 
    }

    const bootStoreDisplays = async () => {
        try {
            if ([ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)) {
                const storeDisplays = JSON.parse(localStorage.getItem(LOCAL_STORAGE_STORE_DISPLAY_KEY) || "{}")
                const { requiredItems = [], scannedItems = [] } = storeDisplays
                bootStoreDisplayItems({requiredItems, scannedItems})
            }
        } catch (error) {
            console.log("🚀 ~ bootStoreDisplays ~ error:", error)
        }
    }
    
    useEffect(() => {
        if ([ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)) {
            loadBootItems({ user })
        }
        if (userRole === ROLE_ANONYMOUS) {
            shopCartReset()
        }
    }, [user?.id])

    useEffect(() => {
        const syncShopCartItemStorageTimeOut = setTimeout(() => {
            if (shopCart.isLoaded && isFirstSyncItemsTime) {
                setIsFirstSyncItemsTime(false)
                return
            }
            if (
                shopCart.isLoaded || 
                [ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)
            ){
                syncShopCartItemStorage({ user, items: shopCart.items })
            }
        }, 1000);
        
        // eslint-disable-next-line consistent-return
        return () => {
            if (
                shopCart.isLoaded || 
                [ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)
            ){
                clearTimeout(syncShopCartItemStorageTimeOut)
            }
        }
    }, [shopCart.version])

    useEffect(() => {
        if (
            shopCart.isLoaded &&
            [ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)
        ){
            syncShopCartItemStorage({ user, items: shopCart.items })
        }
    }, [shopCart.version])

    useEffect(() => {
        loadScandit()
        bootStoreDisplays()
    }, [userRole])
    return (
        <>
            <ConfirmationMessageModal />
            { children }
        </>
    )
}

export default ShopCartStore